.h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: rgb(105, 0, 39);
    margin: 0;
}

.h2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: rgb(105, 0, 39);
    margin: 0;
}

.h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: normal;
    color: rgb(105, 0, 39);
    margin: 0;
}