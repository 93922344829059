.background {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    background-color: rgb(68, 68, 68);
}

.page {
    /* background-image: url(http://s.ytimg.com/yt/imgbin/www-refreshbg-vflC3wnbM.png); */
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
    background-color: rgb(255, 251, 240);
    width: 21cm;
    min-width: 21cm;
    /* height: 29.7cm; */
    height: 1860px;
    /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); */
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    /* margin-top: 30px; */
    /* margin-bottom: 30px; */
    border-radius: 5px;
}

.sidebar {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin: 0;
    flex: 4;
    height: 29.7cm - 20px;
    align-items: flex-start;
    margin-top: 30px;
    margin-left: 10px;
}

.mainSection {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin: 0;
    flex: 9;
    /* background-color: blue; */
    height: 29.7cm - 20px;;
    align-items: flex-start;
    padding-top: 48px;
    margin-left: 20px;
    padding-right: 3px;
}

.paperBg {
    /* background-image: url('../../../public/textured-paper.png'); */
    /* background-color: rgb(255, 244, 223); */
    background-size: 300px;
}

.footer {
    height: 30px;
}

.header {
    height: 30px;
}

@font-face {
    font-family: 'MaterialIcons';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/Material.ttf');
  }
  
  .material-icons {
    font-family: 'MaterialIcons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
  }