.sectionContainer {
    padding-top: 5px;
    /* background-color: bisque; */
    width: 95%;
    
}

.sectionInnerContainer {
    padding-top: 5px;
    /* background-color: bisque; */
    width: 100%;
    border-color: black;
    border-left: 1px;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-style: solid;
    margin-top: 18px;
    padding-bottom: 14px;
    margin-left: 15px;
}

.sectionHeading {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: rgb(105, 0, 39);
    margin: 0;
    margin-top: 1px;
    margin-left: 10px;
}

.subSectionHeading {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: rgb(105, 0, 39);
    margin: 0;
    margin-top: -2px;
    margin-left: 15px;
}

.sectionHeadingContainer {
    display: flex;
    margin-top: -28.5px;
    margin-left: -12.5px;
}

.subSectionHeadingContainer {
    display: flex;
    margin-top: -23.5px;
    margin-left: -7px;
}

.sectionContent {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 22px;
    padding-bottom: 4px;
}