.skillText {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    color: black;
    margin: 0;
    padding-top: 5px;
    padding-left: 6px;
}

.skillContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: -5px;
}

.skillTextContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1;
}

.statContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1
}

.skillRow {
    display: flex;
    width: 100%;
    flex-direction: row;

}