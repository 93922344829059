.text {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    color: black;
    margin: 0;
}

.justified {
    text-align: justify;
    text-justify: inter-word;
}

.textContainer {
    display: flex;
}

.withIcon {
    padding-top: 5px;
    padding-left: 4px;
}